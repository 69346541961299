import { Col, Container, Row, Spinner } from "react-bootstrap";
import Header from "../../components/header"
import { BackButtonSignUp, ContainerSignUp, ContainerSignUpButtons, NextButtonSignUp, StyledInputSignUp, StyledSignUpText } from "../../components/renderField/styles";
import { CardEmployee } from "../welcome/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { StyledBackButton, StyledContainerButtons, StyledContainerLine, StyledContainerParagraph, StyledLoginButton, StyledParagraph, StyledSwitchButton } from "../signup/styles";
import { useToast } from "../../context/Toast";
import 'react-phone-number-input/style.css'
import ptBr from 'react-phone-number-input/locale/pt-BR.json'
import { StyledInputEmployee } from "./inputStyle";
import { verifyUserCellphone } from "../../services/user/user";
import { useAuth } from "../../context/AuthContext";
import { getCompanyId } from "../../services/company/company";
import { endOfMonth, getDate } from "date-fns"



const CellphoneAuth : React.FC = () =>{

    const navigate = useNavigate()
    const { user, saveDataVerifyOnStorage, saveDataSignUpOnStorage } = useAuth()
    const [loading, setLoading] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const {showToast} = useToast()
    const [phone, setPhone] = useState<any>(''); // Tipando o useState para string
    const [error, setError] = useState<string>(''); // Tipando o erro também como string

    // Função para validar o número de telefone
    // const validatePhoneNumber = (): string | null => {
    //     if (!phone) {
    //         showToast('Você precisa informar um número de telefone', 'error')
    //         return null;
    //     }
        
    //     //adiciona o "+" ao DDI
    //     const formattedPhone = phone;
        

    //     return formattedPhone; //retorna o número formatado
    // };

    const checkCellphone = (cellphone: string): boolean => {
        // Regex para validar números de celular brasileiros e internacionais
        const regex = /^(?:\+55\s?)?(?:\(?(\d{2})\)?[\s-]?)?9\d{4}[-\s]?\d{4}$|^(?:\+\d{1,3}\s?)?(?:\(?\d{1,3}\)?[\s-]?)?\d{4}[-\s]?\d{4}$/;
    
        return regex.test(cellphone);
    }
    

    // Função chamada no envio do formulário
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        e.preventDefault();
        setLoadingButton(true);
        // const formattedPhone = validatePhoneNumber();
        
        if (phone && checkCellphone(phone)) {
            // showToast(`Foi enviado um código no seu celular por SMS para o celular ${phone} `, 'success', 6000)

            try {
                let loginData = await verifyUserCellphone(phone);
                
                const dataToSend = {
                    verifyToken: loginData.verifyToken
                }
                saveDataVerifyOnStorage(dataToSend);
                    

                const dataToSave: any = {
                    id: loginData.id,
                    status: loginData.status,
                    first_name: loginData.first_name,
                    last_name: loginData.last_name,
                    company_id: loginData.company_id,
                    company_name: loginData.company_name,
                    role: loginData.role,
                    cellphone: phone
                }

                if (loginData.email !== undefined && loginData.email !== "") {
                    dataToSave.email = loginData.email;
                }
                saveDataSignUpOnStorage(dataToSave);

                showToast(`Autenticado com sucesso!`, 'success', 6000);


                try {
                    setLoading(true)
                    const company = await getCompanyId(loginData.company_id)
        
                    if (!company.Item.surveys_all_month) {
                        const today = new Date()
                        const day = getDate(today)
                        const lastDayOfMonth = getDate(endOfMonth(today))
    
                        if (day >= 20 && day <= lastDayOfMonth) {
                            navigate('/questions')
                        } else {
                            showToast('Estamos fora do periodo de reflexão :(\n\nPara responder a reflexão do mês, você deve acessar durante o período do dia 20 até o último dia do mês.', 'info', 20000)
                            navigate('/welcome')
                        }
                    } else {
                        navigate('/questions')
                    }
                    setLoading(false)
                } catch (error) {
                    throw error
                }

            } catch (error: any) {
                setLoadingButton(false);
                if(error?.response?.data?.statusCode == 404 && error?.response?.data?.error == "UserNotFoundByCellphone") {
                    showToast(`Não encontramos nenhum usuário com o número informado: ${phone}`, 'error', 8000);
                } else {
                    console.error(error);
                    showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                    throw error;
                }
            }
            
            setLoadingButton(false);
            // navigate('/confirmation-code-auth')

        } else {
            showToast('Você precisa informar um número de celular válido!', 'error', 6000);
        }        
    };
    

   
    return(
        <>
            <Header />
            <Container fluid className="d-flex h-100 mt-5 mb-2">
                <Row className="justify-content-center align-self-center w-100">
                    <Col md={9} lg={7}>
                        <CardEmployee>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{
                                    minHeight: '300px',

                                }}>
                                    <Spinner style={{ color: '#c9c9c9' }}></Spinner>
                                </div>
                            ) : (
                                <ContainerSignUp>
                                    <div className="d-flex justify-content-center flex-column ">
                                        <StyledSignUpText>{'Qual é o seu numero de celular?'}</StyledSignUpText>
                                        <div className="d-flex justify-content-center" 
                                            style={{
                                                width: '100%',
                                                flexDirection: 'column',
                                            }}>
                                        
                                            {/* <PhoneNumberInput phone={phone} setPhone={setPhone} /> */}
                                            {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}

                                            <StyledInputEmployee
                                                placeholder="Digite seu número de telefone"
                                                value={phone}
                                                onChange={setPhone}
                                                limitMaxLength={true}
                                                labels={ptBr}  
                                                defaultCountry="BR"                         
                                            />

                                        </div>
                                    </div>
                                    <StyledContainerParagraph>
                                        <StyledContainerLine></StyledContainerLine>
                                        <StyledParagraph>ou</StyledParagraph>
                                        <StyledContainerLine></StyledContainerLine>
                                    </StyledContainerParagraph>

                                    <StyledSwitchButton onClick={() => navigate('/signup')}>Fazer login utilizando o endereço de e-mail</StyledSwitchButton>
                                    <ContainerSignUpButtons>
                                        <StyledContainerButtons>
                                            <StyledBackButton onClick={() => { navigate('/signup') }} >Voltar</StyledBackButton>
                                            
                                            <StyledLoginButton disabled={loadingButton} onClick={handleSubmit}>
                                                {loadingButton ? (
                                                        <Spinner style={{ height: "18px", width: "18px", marginRight: "12px", color: '#191919' }}></Spinner>
                                                ) : (<></>)}
                                                Fazer Login
                                            </StyledLoginButton>
                                        </StyledContainerButtons>
                                        
                                    </ContainerSignUpButtons>
                                </ContainerSignUp>
                            )}
                        </CardEmployee>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CellphoneAuth;